import React from "react"
import { graphql, Link } from "gatsby"
import moment from "moment"
import ScrollAnimation from "react-animate-on-scroll"
import { useI18next } from "gatsby-plugin-react-i18next"

import { Row, Col, Breadcrumb, Button } from "antd"
import PageNotFound from "../../404"
import Layout from "../../../app/layout"
import Seo from "../../../components/seo"
import Container from "../../../components/Container"
import { ChevronRight } from "../../../components/Icon"

import "./karir.less"

const SingleCareerPage = ({
  pageContext: { data: dataAll = {}, language = "id" },
  data: {
    careers: { page_setting = {} },
  },
}) => {
  const { t } = useI18next()

  const data = dataAll[language]

  if (!data) return <PageNotFound />

  const today = moment().unix() * 1000

  const endDate = moment(data.registration_end_date).endOf("day").unix() * 1000

  const outdated = today > endDate

  return (
    <Layout>
      <Seo title={data.job_title} />
      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Breadcrumb.Item>
            <Link to="/">{t("Beranda")}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/informasi/karir/">{t("Karir")}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{data.job_title}</Breadcrumb.Item>
        </Breadcrumb>
      </Container>

      <Container>
        <article
          className="article section text-medium section-padding-top-less"
          style={{ wordBreak: "break-all" }}
        >
          <Row
            gutter={40}
            justify="space-between"
            style={{ margin: "0 -15px" }}
          >
            <Col span={24} md={14} style={{ padding: "0 15px" }}>
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <h1>{data.job_title}</h1>
                <h3>{t("Tentang Pekerjaan")}</h3>
                <p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.job_overview,
                    }}
                  />
                </p>
                <h3>{t("Apa yang Akan Anda Kerjakan")}</h3>
                <p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.job_detail,
                    }}
                  />
                </p>
                <h3>{t("Persyaratan")}</h3>
                <p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.job_requirement,
                    }}
                  />
                </p>
                <h3>{t("Cara Mendaftar")}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.job_step_by_step,
                  }}
                />
                <h3>{t("Dokumen yang Dibutuhkan")}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.job_required_document,
                  }}
                />
                <h3>{t("Tahapan Seleksi")}</h3>
                <p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.job_selection_stage,
                    }}
                  />
                </p>

                <div className="show-mobile" style={{ marginTop: 30 }}>
                  <p>
                    <strong>{t("Lokasi")}</strong>
                    <br />
                    {data.job_location}
                  </p>
                  <p>
                    <strong>{t("Tipe Pekerjaan")}</strong>
                    <br />
                    {data.job_type}
                  </p>
                  <p>
                    <strong>{t("Kode")}</strong>
                    <br />
                    {data.job_code}
                  </p>
                  <p>
                    <strong>{t("Periode Pendaftaran")}</strong>
                    <br />
                    {moment(data.registration_start_date).format(
                      "DD MMMM YYYY"
                    )}{" "}
                    -{" "}
                    {moment(data.registration_end_date).format("DD MMMM YYYY")}
                  </p>

                  <Button
                    size="large"
                    disabled={outdated}
                    href={data.button_url}
                    style={{ width: "100%", marginBottom: 10 }}
                  >
                    {outdated === true
                      ? t("Posisi Sudah Terisi")
                      : data.button_text}
                  </Button>
                  <hr />
                </div>
                <div className="ant-alert ant-alert-warning ant-alert-with-description ant-alert-banner karir-alert">
                  <span
                    role="img"
                    aria-label="exclamation-circle"
                    className="anticon anticon-exclamation-circle ant-alert-icon"
                  >
                    <svg
                      viewBox="64 64 896 896"
                      focusable="false"
                      data-icon="exclamation-circle"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                      <path d="M464 688a48 48 0 1096 0 48 48 0 10-96 0zm24-112h48c4.4 0 8-3.6 8-8V296c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8z" />
                    </svg>
                  </span>
                  <div className="ant-alert-content">
                    <div className="ant-alert-message">
                      {page_setting.join_with_us_title}
                    </div>
                    <div
                      className="ant-alert-description"
                      dangerouslySetInnerHTML={{
                        __html: page_setting.join_with_us_general_information,
                      }}
                    />
                  </div>
                </div>
              </ScrollAnimation>
            </Col>

            <Col span={24} md={8}>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOnce={true}
                className="sticky-sidebar hide-mobile"
              >
                <div>
                  <p>
                    <strong>{t("Lokasi")}</strong>
                    <br />
                    {data.job_location}
                  </p>
                  <p>
                    <strong>{t("Tipe Pekerjaan")}</strong>
                    <br />
                    {data.job_type}
                  </p>
                  <p>
                    <strong>{t("Kode")}</strong>
                    <br />
                    {data.job_code}
                  </p>
                  <p>
                    <strong>{t("Periode Pendaftaran")}</strong>
                    <br />
                    {moment(data.registration_start_date).format(
                      "DD MMMM YYYY"
                    )}{" "}
                    -{" "}
                    {moment(data.registration_end_date).format("DD MMMM YYYY")}
                  </p>

                  <Button
                    size="large"
                    href={data.button_url}
                    style={{ width: "100%", marginBottom: 10 }}
                    disabled={outdated}
                  >
                    {outdated === true
                      ? t("Posisi Sudah Terisi")
                      : data.button_text}
                  </Button>
                  <hr />
                </div>
              </ScrollAnimation>
            </Col>
          </Row>
        </article>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query CareerSingleQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    careers(lang: { eq: $language }) {
      page_setting {
        join_with_us_general_information
        join_with_us_title
      }
    }
  }
`

export default SingleCareerPage
